<template>
  <AppLayout>
    <ViewWrapper
      title="workflows"
      :tab="tab"
      :tabs="_tabs"
      :breadcrumbs="breadcrumbs"
      @quickHelp="showQuickHelp"
    >
      <!-- header -->

      <template #header-actions>
        <BaseButton
          v-if="showAddWorkflows"
          icon="eva-plus"
          label="add workflow"
          class="q-ml-md"
          @click="addWorkflow"
        />
      </template>

      <!-- ... -->

      <!-- quick help -->

      <QuickHelp
        v-model="quickHelp"
        :page-help="'WORKFLOW'"
        @videoclick="videoHelp = true"
      />

      <!-- ... -->

      <!-- QuickHelpModal-->
      <QuickHelpModal
        v-model="videoHelp"
        :is-header="false"
        width="70vw"
        height="max-content"
        has-footer
      >
        <!-- Content -->

        <template #default>
          <div
            style="
              position: relative;
              padding-bottom: calc(50.70717423133236% + 42px);
              height: 100%;
            "
            class="expand"
          >
            <iframe
              src="https://walk.ezofis.com/embed/clxbirpmz0rgat2oea5devc2m"
              title="Workflow - Process Design"
              allow="clipboard-write"
              frameborder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowfullscreen
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
            ></iframe>
          </div>
        </template>

        <template #footer>
          <BaseButton
            label="Close"
            icon="mdi-close"
            color="primary"
            @click="videoHelp = false"
          />
        </template>
        <!-- ...-->
      </QuickHelpModal>
      <!-- ...-->

      <!-- child routes -->

      <router-view :key="$route.fullPath" />

      <!-- ... -->
    </ViewWrapper>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/app/AppLayout.vue";
import ViewWrapper from "@/components/common/view-wrapper/ViewWrapper.vue";
import QuickHelp from "@/components/common/QuickHelp.vue";
import QuickHelpModal from "@/components/common/popup/QuickHelpModal.vue";

export default {
  name: "Workflows",

  components: { AppLayout, ViewWrapper, QuickHelp, QuickHelpModal },

  data() {
    return {
      breadcrumbs: [
        {
          id: this.$nano.id(),
          label: "dashboard",
          route: "dashboard",
        },
        {
          id: this.$nano.id(),
          label: "workflows",
          route: "workflows-overview",
        },
        {
          id: this.$nano.id(),
          label: "",
          route: "",
        },
      ],
      tabs: [
        {
          id: this.$nano.id(),
          label: "overview",
          icon: "mdi-view-dashboard-outline",
          route: "workflows-overview",
          access: true,
          accessLabel: "Overview",
        },
        {
          id: this.$nano.id(),
          label: "inbox",
          icon: "mdi-view-dashboard-outline",
          route: "workflows-inbox",
          access: true,
        },
        {
          id: this.$nano.id(),
          label: "browse",
          icon: "eva-folder-outline",
          route: "workflows-browse",
          access: false,
          accessLabel: "Manage Workflows",
        },
        {
          id: this.$nano.id(),
          label: "trash",
          icon: "eva-trash-2-outline",
          route: "workflows-trash",
          access: false,
          accessLabel: "Manage Workflows",
        },
        {
          id: this.$nano.id(),
          label: "reports",
          icon: "eva-file-text-outline",
          route: "workflows-report",
          access: false,
          accessLabel: "Audit Report",
        },
      ],
      tab: "workflows-overview",
      quickHelp: false,
      videoHelp: false,
    };
  },

  computed: {
    showAddWorkflows() {
      if (this.$store.state.profileMenus.length)
        return this.$store.state.profileMenus.find((row) => row.workflows).Menu[
          "Manage Workflows"
        ];

      return false;
    },

    _tabs() {
      if (this.$store.state.profileMenus.length) {
        this.tabs.forEach((tab) => {
          if (!tab.access) {
            tab.access = this.$store.state.profileMenus.find(
              (row) => row.workflows
            )?.Menu[tab.accessLabel];
          }
        });
      }
      return this.tabs.filter((tab) => tab.access);
    },
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.tab = this.$route.name;
        const index = this.breadcrumbs.length - 1;
        this.breadcrumbs[index].label = this.$route.meta.breadcrumb;
      },
    },
  },

  methods: {
    addWorkflow() {
      this.$router.push({ name: "workflow-builder" });
    },

    showQuickHelp() {
      this.quickHelp = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
